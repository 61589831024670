import './App.scss';
import './styles/loading.css';
import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import { UserProvider } from './Contexts/UserContext';

import Root from './Components/Root';
import Login from './Components/Login';
import Home from './Components/Home';
import Videos from './Components/Videos';
import ErrorPage from './error-page';
import IsLoggedIn from './Components/IsLoggedIn';
import Categories from './Components/Categories';

const router = (isLoggedIn) => createBrowserRouter([
  {
    path: "/",
    element: isLoggedIn ? <Root/> : <Navigate to="/login" />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/categories",
        element: <Categories/>
      },
      {
        path: "/:cat",
        element: <Videos />,
      },
    ],
  },
  {
    path: "/login",
    element: isLoggedIn ? <Navigate to="/" /> : <Login/>,
  },
]);

function App() {

  const authState = IsLoggedIn();

  if(authState === null){
    return(
      <Suspense fallback="loading">
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      <UserProvider>
        <RouterProvider router={router(authState)} />
      </UserProvider>
    </Suspense>
  );
}

export default App;
